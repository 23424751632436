<template>
  <div
    v-if="isRatingOk"
    class="flex items-center text-white font-sans flex-wrap mt-4 md:mt-0 xs:flex-nowrap text-md md:text-base"
  >
    <Stars :rating="+mappedScore" :best-rating="5" />
    <span v-if="isUSDomain" class="mx-2">{{ score }}/5</span>
    <span v-else class="mx-2 font-cocogoose" data-test="reviews-score">{{ score }}/10</span>
    <span
      v-if="count >= 100"
      role="button"
      tabindex="0"
      class="cursor-pointer hover:underline text-md md:text-base"
      data-test="reviews-number"
      id="locations_reviews_head_link"
      @keypress.stop.prevent="scrollToReviews"
      @click.stop.prevent="scrollToReviews"
      >{{ $i18n('customer.num-reviews', { total: numberFormat(count) }) }}</span
    >
  </div>
</template>

<script>
import Stars from './Stars.vue';

export default {
  components: {
    Stars,
  },
  computed: {
    reviewsMeta() {
      return this.$store.state.reviewsMeta;
    },
    language() {
      return this.$store.state.language;
    },
    reviewsScoreAvg() {
      if (!this.reviewsMeta) {
        return 0;
      }
      if (this.reviewsMeta.reviews.score) {
        return this.reviewsMeta.reviews.score;
      }

      return 2.4;
    },
    count() {
      return this.reviewsMeta.reviews.count;
    },
    score() {
      if (this.isUSDomain) {
        return this.numberFormat(
          (this.reviewsScoreAvg / 2).toFixed(1).replace(/[.,]0$/, '')
        );
      }
      return this.numberFormat(
        this.reviewsScoreAvg.toFixed(1).replace(/[.,]0$/, '')
      );
    },
    mappedScore() {
      return (+this.reviewsScoreAvg / 10) * 5;
    },
    isRatingOk() {
      return (4 / 5) * 5 <= this.reviewsScoreAvg;
    },
    isFrenchDomain() {
      return this.language.lang === 'fr' || this.language.lang === 'fr-be';
    },
    isUSDomain() {
      return this.$store.state.language.lang === 'en-us';
    },
  },
  methods: {
    numberFormat(number) {
      return new Intl.NumberFormat(
        this.isFrenchDomain ? 'nl' : this.language.lang
      ).format(number);
    },
    scrollToReviews() {
      const reviewsWrapper = document.querySelector('.reviews-wrapper');
      if (!reviewsWrapper) {
        return;
      }
      reviewsWrapper.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
